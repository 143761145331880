<template>
  <div>
    <div
      v-if="filters && filters.length"
      class="filter-popular__root"
    >
      <div class="filter-popular__filters">
        <div class="filter-items">
          <template
            v-for="filter in filters"
            :key="filter.id"
          >
            <SelectFilterWithCheckboxTree
              v-if="visibleSelectFilter.includes(filter.slug)"
              :filter="filter"
              :selected-filters="selectedFilters"
              class="filter-item select-filter"
              @onChangeFilterVariant="tapVariantMulti"
            />
            <SelectFilter
              v-if="Boolean(filter.filterTemplate === 'checkbox_list' && filter.id !== 'categories')"
              :filter="filter"
              :selected-filters="selectedFilters"
              class="filter-item select-filter"
              :is-disabled="isDisabledFilters"
              @onChangeFilterVariant="tapVariantMulti"
            />

            <FilterButtonCheckboxList
              v-if="filter.filterTemplate==='checkbox_button_group' || filter.filterTemplate==='button_select'"
              :filter="filter"
              :selected-filters="selectedFilters"
              class="filter-item check-box-btn"
              :is-disabled="isDisabledFilters"
              @onChangeFilterVariant="tapVariantMulti"
            />

            <FilterRange
              v-if="filter.filterTemplate === 'checkbox_list_with_range'"
              :filter="filter"
              :selected-filters="selectedFilters"
              class="filter-item"
              :is-disabled="isDisabledFilters"
              @onChangeFilterVariant="tapVariantMulti"
            />
          </template>
        </div>
      </div>
      <div class="popular-filters__bottom">
        <label
          v-if="checkBoxTagsList && checkBoxTagsList.length"
          class="select-filter__title"
        >Особенности
        </label>
        <div class="bottom-wrapper">
          <div
            v-if="checkBoxTagsList && checkBoxTagsList.length"
            class="catalog-filters__tags"
          >
            <div class="filter-tags">
              <FilterCheckboxTag
                v-for="(tag, idx) in checkBoxTagsList"
                :key="`filter-tags-${tag.id}-${idx}`"
                :filter="tag"
                :selected-filters="selectedFilters"

                @onChangeFilterVariant="tapVariantMulti"
              />
            </div>
          </div>

          <div class="reset-block">
            <button
              class="btn primary btn-amount --shadow"
              @click="onShowProducts"
            >
              <span v-if="amountProductWithoutGroup">
                {{ amountProductWithoutGroup }}
                {{ $handleWordDeclension(amountProductWithoutGroup, ['предложение', 'предложения', 'предложений']) }}
              </span>

              <span v-else> Нет предложений</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <FilterLoader />
    </div>
  </div>
</template>

<script setup lang="ts">
import FilterRange from '~/components/filters/FilterRange.vue';
import SelectFilter from '~/components/filters/SelectFilter.vue';
import FilterCheckboxTag from '~/components/filters/FilterCheckboxTag.vue';
import FilterButtonCheckboxList from '~/components/filters/FilterButtonCheckboxList/FilterButtonCheckboxList.vue';
import type { IFilter } from '#sitis/internal/controllers/filters/models/Filter';
import SelectFilterWithCheckboxTree from '~/components/filters/SelectFilterWithCheckboxTree.vue';
import FilterLoader from '~/components/loaders/FilterLoader.vue';

const props = defineProps<{
	filters: IFilter[];
	selectedFilters: Record<string, object>;
	productsLength: number;
	isShowOnlyFilters: boolean;
	urlTo:string;
	isDisabledFilters:boolean;
	amountProductWithoutGroup:number;
}>();

const emit = defineEmits<{
	changeFilter: [options: Record<string, object>],
	onLinkToCatalog: [],
	onClearFilters: []
}>();

const { $handleWordDeclension } = useNuxtApp();

const selectedFiltersList = ref<Record<string, object> | null>({});

const tapVariantMulti = (filterSlug: string | number, variantSlugs: Record<string, string> | null, isDisabled?: boolean) => {
  if (isDisabled) {
    return null;
  }
  // Изменение текущего фильтра
  const filterSelected = { ...props.selectedFilters };

  if (filterSelected) {
    filterSelected[filterSlug] = variantSlugs || {};

    if (variantSlugs === null || Object.keys(variantSlugs).length <= 0) {
      delete filterSelected[filterSlug];
      if (selectedFiltersList.value) {
        delete selectedFiltersList.value[filterSlug];
      }
    }
  }

  selectedFiltersList.value = { ...selectedFiltersList.value, ...filterSelected };

  emit('changeFilter', selectedFiltersList.value);
};

const visibleSelectFilter = computed(() => {
  const slugs = [...(props.filters || [])]
    .filter((t: IFilter) => ['checkbox_tree']
      .includes(t.filterTemplate))
    .map((t: IFilter) => t.slug);

  if (props.isShowOnlyFilters) {
    slugs.splice(slugs.findIndex((t) => t === 'categories'), 1);
  }

  return slugs;
});

const checkBoxTagsList = computed(() => props.filters.filter((t) => t.filterTemplate === 'checkbox_boolean_button'));

const onShowProducts = () => {
  const filter: string[] = [];
  const pageUrl = props.urlTo;

  if (selectedFiltersList && selectedFiltersList.value && Object.keys(selectedFiltersList.value).length) {
	  if (selectedFiltersList && 'group_planing_product' in selectedFiltersList.value) {
		  delete selectedFiltersList.value.group_planing_product;
	  }

    Object.keys(selectedFiltersList.value).forEach((key) => {
      filter.push(`${key}=${Object.keys(selectedFiltersList.value![key]).join(',')}`);
    });

    navigateTo(`${pageUrl}/f/${filter.join('/')}`);

    return;
  }
  navigateTo(`${pageUrl}`);
};
watch(() => props.selectedFilters, (newVal, oldVal) => {
  selectedFiltersList.value = props.selectedFilters;
});
onMounted(() => {
  selectedFiltersList.value = props.selectedFilters;
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.filter-popular__root {
	//margin-bottom: 90px;
	display: flex;
	align-items: flex-end;
	gap: 30px;
}

.filter-popular__filters {
	display: flex;
	gap: 15px;
	align-items: flex-start;
	flex: 1;
}

.filter-items {
	display: flex;
	flex-wrap: wrap;
	gap: 30px 15px;

	& > div {
		width: auto;
		max-width: 300px;
		min-width: 200px;
	}

	& > div:nth-child(1) {
		min-width: unset;
		max-width: unset;
	}
}

.filter-item {
	min-width: 120px;
	max-width: 300px;
	width: auto;
}

.select-filter {
	width: 30%;
}

.popular-filters__bottom {
	max-width: 300px;
	width: 100%;

	& > h5 {
		color: var(--primary);
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 10px;
	}

	& > .bottom-wrapper {
		display: flex;
		justify-content: space-between;
		gap: 40px;
		align-items: flex-start;
	}
}

.catalog-filters__tags {
	display: flex;
	flex-direction: column;
	width: 80%;
}

.filter-tags {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	gap: 15px;
}

.reset-block {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	gap: 10px;
	width: 100%;

	& > span {
		color: var(--dark-primary);
		font-size: 16px;
		font-weight: 500;
	}

	& > div {
		display: flex;
		align-items: center;
		gap: 10px;
		color: var(--primary);
		font-size: 16px;
		font-weight: 500;
		cursor: pointer;

		& > img {
			width: 12px;
			filter: invert(10%) sepia(37%) saturate(6985%) hue-rotate(259deg) brightness(88%) contrast(92%);
		}
	}
}

.btn-amount {
	white-space: nowrap;
	width: 100%;
  font-size: 18px;
}

@include media('xl') {
	.filter-popular__root{
		gap: 10px;
	}

	.popular-filters__bottom{
		max-width: 190px;
	}

	.btn-amount{
		max-width: 200px;
		font-size: 17px;
	}

	.filter-items {
		gap: 30px 6px;

		& > div {
			width: auto;
			max-width: 290px;
			min-width: 200px;
		}

		& > div:nth-child(1) {
			min-width: 200px;
			max-width: 200px;
		}
	}
}

@include media('lg') {
	.bottom-wrapper {
		flex-direction: column;
	}

	.catalog-filters__tags {
		width: 100%;
	}

	.filter-popular__root{
		flex-direction: column;
		align-items: flex-start;
	}

	.catalog-filters__bottom {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}

	.reset-block {
		justify-content: space-between;
		width: 100%;
	}

	.filter-popular__filters {
		flex-direction: column-reverse;
		width: 100%;
	}

	.filter-items{
		& > div:nth-child(1) {
			width: auto;
			min-width: max-content;
		}
	}

	.filter-item {
		width: 100%;
	}

	.popular-filters__bottom{
		display: flex;
		justify-content: flex-end;
		width: 100%;
		max-width: initial;
		margin-top: 20px;
	}
}

@include media('xl') {
	.catalog-filters__tags {
		width: 70%;
	}
}

@include media('md') {
	.popular-filters__bottom{
		& .bottom-wrapper{
			width: 100%;
		}
	}

	.btn-amount{
		width: 100%;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 15.238px;
		letter-spacing: -0.36px;
		max-width: initial;
	}

	.filter-items {
		gap: 24px;

		& > div{
			width: 100%;
			max-width: initial;
		}

		& > div:nth-child(1),
		& > div:nth-child(2) {
			width: 100%;
			max-width: initial;
		}
	}
}

@include media('sm') {
	.catalog-filters__bottom {
		flex-direction: column;
		align-items: flex-start;
	}

	.filter-tags {
		gap: 5px;
	}

	.reset-block {
		flex-direction: column;
		gap: 10px;
		width: 100%;
		margin-top: 10px;

		& > button {
			width: 100%;
		}
	}
}

</style>
